import type { FC } from 'react'
import React from 'react'
import { backendHook } from '@api/trpc'
import { Select } from '@components/ui/forms/fields/Select'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  Button,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toastSonner,
} from '@goatlab/react-ui'
import {
  useReportForm,
  useReportOptions,
} from '@sodium/shared-frontend-schemas'

interface ReportDialogType {
  accountId?: string
  postId?: string
  commentId?: string
  onFormSubmitted?: () => void
  onDialogClosed?: () => void
}

export const ReportOption: FC<ReportDialogType> = ({
  postId,
  accountId,
  commentId,
  onFormSubmitted,
  onDialogClosed,
}) => {
  const submitReport = backendHook.backend.posts.reportContent.useMutation()
  const { t } = useTypedTranslation()

  const { formHook } = useReportForm({
    defaultValues: {
      postId: postId || '',
      accountId: accountId || '',
      commentId: commentId || '',
      reason: '',
    },
  })

  const options = useReportOptions()

  const closeDialog = () => {
    onDialogClosed?.()
    formHook.reset()
  }

  const onSubmit = async () => {
    const report = formHook.getValues()
    toastSonner.promise(submitReport.mutateAsync(report), {
      loading: 'Reporting...',
      success: () => {
        onFormSubmitted?.()
        closeDialog()
        return t('content-reported', { ns: 'report' })
      },
      error: (error) => {
        console.error('error??', error)
        return 'There was an error reporting'
      },
    })
  }

  const reason = formHook.watch('reason')

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t('report', { ns: 'common' })}</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="pt-4">
        <h3 className="font-bold">{t('why-reporting', { ns: 'common' })}</h3>
        <p className="pt-2 text-neutral-500">
          {t('reporting-description', { ns: 'common' })}
        </p>
      </div>
      <Select
        name={formHook.path.reason}
        formHook={formHook}
        placeholder={t('select-reason', { ns: 'report' })}
        options={options}
        inModal
      />
      <DialogFooter>
        <Button onClick={onSubmit} disabled={!reason || submitReport.isPending}>
          {t('report', { ns: 'common' })}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
