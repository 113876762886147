import type { useCommentActivitiesStore } from '@sodium/shared-frontend-schemas'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { api } from '@api/api'
import { useAuth } from '@components/auth/store/auth.store'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@components/ui/dropdown-menu'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { Functions } from '@goatlab/js-utils'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Button, Dialog, DialogTrigger, toastSonner } from '@goatlab/react-ui'
import { sodiumConfig } from '@src/sodiumConfig'
import { defaultAccountImage } from '@src/utils/constants'
import { fromNowShort } from '@src/utils/date/fromNowWithDateFns'
import { MessageSquareWarningIcon, MoreVertical } from 'lucide-react'
import type { PostComment } from './CommentsList'
import { ReportOption } from '../post/ReportOption'
import { UnFollowOption } from '../post/UnFollowOptions'
import { CommentEditMessage } from './CommentEditMessage'
import { FooterActionButton } from './FooterActionButton'

interface CommentMessageProps {
  comment: PostComment
  onCommentLikePressed?: (comment: PostComment) => void
}

export const CommentMessage: FC<CommentMessageProps> = ({
  comment,
  onCommentLikePressed,
}) => {
  const { user } = useAuth()
  const {
    setCommentActivity,
    setIsEditing,
    setCommentActivityContent,
    resetCommentActivity,
    activity: activityStore,
    isEditing,
  } = useCommentActivitiesStore()
  const [isReportModal, setIsReportModal] = useState(false)
  const isOwner = user?.account?.id === comment?.accountId
  const { t } = useTypedTranslation()

  const handleReset = async () => {
    resetCommentActivity()
  }

  const deleteComment = api.posts.useDeletePostComment({
    accountId: comment?.account.id,
    reset: handleReset,
  })

  const handleDeleteComment = async () => {
    if (!comment?.id || !comment.postId) {
      return
    }
    toastSonner.promise(
      deleteComment.mutateAsync({
        commentId: comment.id,
        postId: comment.postId,
      }),
      {
        loading: 'Deleting your comment...',
        success: () => 'Comment deleted',
        error: (error) => {
          console.error('error??', error)
          return 'There was an error deleting your comment'
        },
      },
    )
  }

  const handleEditComment = async () => {
    if (!comment?.id || !comment.postId) {
      return
    }

    setCommentActivity(comment)
    setCommentActivityContent(comment.content)
    setIsEditing(true)
  }

  const isEditingComment = useMemo(() => {
    return !!activityStore && isEditing && activityStore.id === comment.id
  }, [activityStore, isEditing, comment])

  const _onLikePressedComment = useCallback(
    Functions.debounce(() => {
      onCommentLikePressed?.(comment)
    }, 40),
    [comment],
  )

  if (!comment) return null

  return (
    <div
      className={`flex w-full px-8 ${isEditingComment ? 'py-4' : 'py-2'} items-start`}
    >
      {/* Avatar */}
      <div>
        <Avatar className="h-7 w-7 md:h-7 md:w-7" data-cy="user-avatar">
          <AvatarImage
            src={comment?.account?.profilePicture || defaultAccountImage}
            alt={comment?.account?.slug || undefined}
          />
          <AvatarFallback>{comment?.account?.slug}</AvatarFallback>
        </Avatar>
      </div>

      {/* Main Content */}
      <div className="w-full flex-1 gap-2 px-2">
        <div className="flex items-center">
          <strong className="text-sm">{comment?.account?.displayName}</strong>
          <span className="ml-2 text-sm text-gray-500">
            {fromNowShort(comment?.updated)}
          </span>
          {comment?.updated !== comment?.created && (
            <span className="ml-2 text-sm text-gray-500">(edited)</span>
          )}
        </div>

        <div className="flex w-full flex-1">
          {isEditingComment ? (
            <CommentEditMessage />
          ) : (
            <RichTextDisplay content={comment.content} />
          )}
        </div>
      </div>

      {/* Reactions */}
      <div className="flex w-1/6 items-center justify-center">
        <FooterActionButton
          icon={sodiumConfig.icons.likePressed}
          iconActive={sodiumConfig.icons.likeDefault}
          active={comment.liked}
          onIconPressed={_onLikePressedComment}
        />
        {comment.reactionsCount ? (
          <span className="text-sm text-gray-500">
            {comment.reactionsCount}
          </span>
        ) : null}
      </div>

      {/* Dropdown Menu */}
      <div className="items-center justify-center">
        <Dialog open={isReportModal} onOpenChange={setIsReportModal}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost" className="h-10 w-10">
                <MoreVertical className="h-4 w-4" />
                <span className="sr-only">More</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {isOwner ? (
                <>
                  <DropdownMenuItem onClick={handleEditComment}>
                    {t('edit-comment', { ns: 'common' })}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="text-red-500"
                    onClick={handleDeleteComment}
                  >
                    {t('delete-comment', { ns: 'common' })}
                  </DropdownMenuItem>
                </>
              ) : (
                <>
                  <DropdownMenuItem>
                    <UnFollowOption account={comment?.account} />
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DialogTrigger asChild>
                    <DropdownMenuItem className="text-red-500">
                      <MessageSquareWarningIcon
                        color="red"
                        className="mr-2 h-4 w-4"
                      />
                      {t('report', { ns: 'common' })}
                    </DropdownMenuItem>
                  </DialogTrigger>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
          <ReportOption
            accountId={comment.accountId}
            postId={comment.postId || ''}
            onDialogClosed={() => setIsReportModal(false)}
          />
        </Dialog>
      </div>
    </div>
  )
}
