import type { FC, ReactElement } from 'react'
import React, { memo, useEffect, useState } from 'react'
import { motion } from 'framer-motion'

interface FooterButtonProps {
  text?: string
  icon: ReactElement
  iconActive: ReactElement
  active?: boolean
  onIconPressed?: () => void
  onTextPressed?: () => void
}

export const FooterActionButton: FC<FooterButtonProps> = memo(
  ({
    text,
    icon,
    iconActive,
    active = false,
    onIconPressed,
    onTextPressed,
  }) => {
    const [liked, setLiked] = useState(active)

    useEffect(() => {
      setLiked(active)
    }, [active])

    return (
      <div className="flex items-center p-2">
        <div className="flex items-center justify-center">
          {/* Reemplazo de Button por un contenedor sin acciones */}
          <motion.div
            className="relative cursor-pointer pr-2"
            onClick={() => {
              setLiked((prev) => !prev)
              if (onIconPressed) onIconPressed()
            }}
          >
            {/* Icono activo (visible cuando liked es true) */}
            <motion.div
              className="absolute"
              whileHover={{ scale: 1.1 }}
              initial={{ scale: liked ? 0 : 1 }}
              animate={{ scale: liked ? 0 : 1 }}
            >
              {iconActive}
            </motion.div>

            {/* Icono por defecto (visible cuando liked es false) */}
            <motion.div
              whileHover={{ scale: 1.1 }}
              initial={{ scale: liked ? 1 : 0 }}
              animate={{ scale: liked ? 1 : 0 }}
            >
              {icon}
            </motion.div>
          </motion.div>
          <motion.span
            whileHover={{ scale: 1.1 }}
            className="ml-2 cursor-pointer whitespace-nowrap text-xs font-medium text-gray-500"
            onClick={onTextPressed}
          >
            {text}
          </motion.span>
        </div>
      </div>
    )
  },
)
