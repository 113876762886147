import type { RouterOutputs } from '@api/trpc'
import type { FC } from 'react'
import React, { useCallback } from 'react'
import { api } from '@api/api'
import { VirtualFeed } from '@components/ui/VirtualFeed'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@goatlab/react-ui'
import { useHomeFeedStore } from '@sodium/shared-frontend-schemas'
import { sodiumConfig } from '@src/sodiumConfig'
import { AccountSearchResult } from './AccountSearchResult'

export type ActivityItem =
  RouterOutputs['backend']['posts']['getPostComments']['data'][0]

interface AccountsLikedPostsDialogProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
}

export const AccountsLikedPostsDialog: FC<AccountsLikedPostsDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const { selectedPost } = useHomeFeedStore()
  const postId = selectedPost?.id
  const getAccountsLikedPostHook = api.posts.useGetAccountsThatLikedPost({
    postId,
  })

  const renderAccount = useCallback(
    ({
      item,
      virtualItem,
    }: {
      item: ActivityItem['account']
      virtualItem: any
    }) => {
      return (
        <div key={virtualItem.key}>
          <AccountSearchResult
            account={item}
            onAvatarPressed={() => {
              setIsDialogOpen(false)
            }}
            accessoryRight={
              <div className="flex">{sodiumConfig.icons.likePressed}</div>
            }
          />
        </div>
      )
    },
    [],
  )

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(opened) => {
        setIsDialogOpen(opened)
      }}
    >
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>{sodiumConfig.keywords.likes}</DialogTitle>
          <DialogDescription>{sodiumConfig.keywords.likes}</DialogDescription>
        </DialogHeader>
        <div className="scrollbar-hide max-h-screen items-center overflow-scroll">
          <VirtualFeed
            infiniteQuery={getAccountsLikedPostHook}
            renderItem={renderAccount}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
