import type { FC } from 'react'
import React, { memo, useState } from 'react'
import { api } from '@api/api'
import { useAuth } from '@components/auth/store/auth.store'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@components/ui/dropdown-menu'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Button, Dialog, DialogTrigger, toastSonner } from '@goatlab/react-ui'
import { useNewPostStore } from '@sodium/shared-frontend-schemas'
import { fromNowWithDateFns } from '@src/utils/date/fromNowWithDateFns'
import { MessageSquareWarningIcon, MoreVertical } from 'lucide-react'
import type { Post } from './HomePosts'
import { ReportOption } from '../post/ReportOption'
import { UnFollowOption } from '../post/UnFollowOptions'
import { AccountSearchResult } from './AccountSearchResult'

interface PostAccountProps {
  post: Post
  showPostModal: () => void
  disabled?: boolean
}

export const PostAccount: FC<PostAccountProps> = memo(
  ({ post, showPostModal }) => {
    const [isReportModal, setIsReportModal] = useState(false)
    const { user } = useAuth()
    const deletePostHook = api.posts.useDeletePost({
      visibleAccountId: post?.account.id,
      beforeMutate: async () => {},
      afterMutate: async () => {},
      onSuccess: () => {},
    })

    const { setPostId, setAssets, setContent, resetKey } = useNewPostStore()
    const isOwner = user?.account?.id === post?.account.id
    const { t } = useTypedTranslation()

    const handleDeletePost = () => {
      if (!post.id) {
        return
      }

      toastSonner.promise(deletePostHook.mutateAsync({ postId: post.id }), {
        loading: 'Deleting your post...',
        success: () => 'Post deleted',
        error: (error) => {
          console.error('error??', error)
          return 'There was an error deleting your post'
        },
      })
    }

    const handleEditPost = () => {
      if (!post.id) {
        return
      }

      setPostId(post.id)
      setContent(post.content)
      setAssets(post?.assets)
      resetKey()
      showPostModal()
    }

    return (
      <div className="flex pt-2">
        <AccountSearchResult
          account={post.account}
          bottomText={<>{fromNowWithDateFns(post.created)}</>}
        />

        <div className="items-center justify-center pt-2">
          <Dialog open={isReportModal} onOpenChange={setIsReportModal}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="icon" variant="ghost" className="h-8 w-8">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">More</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {isOwner ? (
                  <>
                    <DropdownMenuItem onClick={() => handleEditPost()}>
                      {t('edit-post', { ns: 'common' })}
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="text-red-500"
                      onClick={() => handleDeletePost()}
                    >
                      {t('delete-post', { ns: 'common' })}
                    </DropdownMenuItem>
                  </>
                ) : (
                  <>
                    <DropdownMenuItem>
                      <UnFollowOption account={post?.account} />
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DialogTrigger asChild>
                      <DropdownMenuItem className="text-red-500">
                        <MessageSquareWarningIcon
                          color="red"
                          className="mr-2 h-4 w-4"
                        />
                        {t('report', { ns: 'common' })}
                      </DropdownMenuItem>
                    </DialogTrigger>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <ReportOption
              accountId={post?.account.id}
              postId={post?.id}
              onDialogClosed={() => setIsReportModal(false)}
            />
          </Dialog>
        </div>
      </div>
    )
  },
)
