import type { FC } from 'react'
import React from 'react'
import { useRouter } from 'next/router'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { sodiumConfig } from '@src/sodiumConfig'
import { defaultAccountImage } from '@src/utils/constants'
import { UserRoundXIcon } from 'lucide-react'
import { useChatActions } from '../../ui/chat/store/ChatStore'

interface ReportModalType {
  account: {
    id: string
    displayName?: string | null
    profilePicture?: string | null
    slug?: string | null
  }
  onPressed?: () => void
}

export const UnFollowOption: FC<ReportModalType> = ({ account }) => {
  const { setCurrentChatRecipient, setCurrentChatId } = useChatActions()
  const { t } = useTypedTranslation()
  const router = useRouter()
  const routes = useStateAwareRoutes()

  const OnUnfollowPressed = async () => {
    setCurrentChatId(account.id)
    setCurrentChatRecipient({
      _id: account?.id,
      name: account?.displayName,
      avatar: account?.profilePicture || defaultAccountImage,
    })
    return await router.push(
      routes.dynamic.ACCOUNT_INBOX({
        accountId: account.id,
      }),
    )
  }

  return (
    <div onClick={OnUnfollowPressed} className="flex flex-row items-center">
      <UserRoundXIcon
        className="mr-2 h-4 w-4"
        color={sodiumConfig.colors.iconColor}
      />
      {t('stop-following', { ns: 'common' })}
    </div>
  )
}
