import type { RouterOutputs } from '@api/trpc'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { api } from '@api/api'
import { VirtualFeed } from '@components/ui/VirtualFeed'
import type { Post } from './HomePosts'
import { CommentMessage } from './CommentMessage'

export type ActivityItem =
  RouterOutputs['backend']['posts']['getPostComments']['data'][0]
export type PostComment = Post['activities'][0]
interface CommentsListProps {
  postId?: string
  preloadedComments?: Post['activities']
  isPostsLoading: boolean
  onCommentLikePressed?: (comment: PostComment) => void
}

export const CommentsList: FC<CommentsListProps> = ({
  postId,
  preloadedComments,
  onCommentLikePressed,
}) => {
  const getCommentsHook = api.posts.useGetPostComments({
    postId,
  })

  const comments = getCommentsHook.data?.pages.flatMap((c) => c.data) || []

  const mergedComments: PostComment[] = useMemo(() => {
    const commentsMap = new Map<string, PostComment>()

    preloadedComments?.forEach((comment: PostComment) => {
      if (comment.id) {
        commentsMap.set(comment.id, comment)
      }
    })

    // We need to add the comments keeping the 'liked' value
    // if it already exists
    comments.forEach((comment: PostComment) => {
      if (comment.id) {
        const existingComment = commentsMap.get(comment.id)
        commentsMap.set(comment.id, {
          ...comment,
          liked: existingComment?.liked ?? comment.liked,
          likedCount: existingComment?.reactionsCount ?? comment.reactionsCount,
        })
      }
    })

    return Array.from(commentsMap.values()).sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
    )
  }, [preloadedComments, comments])

  const renderMessage = useCallback(
    ({ item, virtualItem }: { item: PostComment; virtualItem: any }) => (
      <div key={virtualItem.key}>
        <CommentMessage
          comment={item}
          onCommentLikePressed={onCommentLikePressed}
        />
      </div>
    ),
    [mergedComments],
  )
  return (
    <VirtualFeed
      infiniteQuery={getCommentsHook}
      renderItem={renderMessage}
      overrideData={mergedComments}
    />
  )
}
