import { api } from '@api/api'
import { Input } from '@components/ui/forms/fields/Input'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Button } from '@goatlab/react-ui'
import { Form, useZodFormHook } from '@goatlab/react-zod-form'
import {
  useAccountStore,
  useCommentActivitiesStore,
} from '@sodium/shared-frontend-schemas'
import { z } from 'zod'

export const commentSchema = z.object({
  comment: z.string(),
})

export const CommentEditMessage = () => {
  const { selectedAccount } = useAccountStore()
  const {
    activity: activityStore,
    activityContent: commentActivity,
    isEditing,
    resetCommentActivity,
  } = useCommentActivitiesStore()

  const { t } = useTypedTranslation(['common', 'forms'])

  const formHook = useZodFormHook({
    schema: commentSchema,
    defaultValues: {
      comment: commentActivity,
    },
  })

  const handleReset = async () => {
    resetCommentActivity()
  }

  const updateComment = api.posts.useUpdateComment({
    reset: handleReset,
  })

  const isEditingComment = !!activityStore && isEditing

  const handleSubmitComment = async () => {
    const comment = formHook.getValues().comment

    if (!comment || !selectedAccount || !isEditingComment) {
      return
    }
    return await updateComment.mutateAsync({
      commentId: activityStore.id,
      content: comment,
      postId: activityStore.postId || '',
    })
  }

  const commentChanged = formHook.watch('comment') !== commentActivity

  return (
    <Form
      formHook={formHook}
      onSuccess={async () => {
        await handleSubmitComment()
        formHook.reset()
      }}
    >
      <div className="flex flex-col items-center justify-center p-4">
        <Input
          className="w-full"
          name={formHook.path.comment}
          placeholder={t('placeholder-new-post', { ns: 'forms' })}
          formHook={formHook}
        />
        {/* <RichTextArea
          name={formHook.path.comment}
          label={t('placeholder-new-post', { ns: 'forms' })}
          formHook={formHook}
        />*/}
        <div className="flex gap-4 py-2">
          <Button disabled={!commentChanged} type="submit">
            {t('text-save-changes', { ns: 'common' })}
          </Button>
          <Button
            variant={'outline'}
            type="reset"
            onClick={() => resetCommentActivity()}
          >
            {t('text-cancel', { ns: 'common' })}
          </Button>
        </div>
      </div>
    </Form>
  )
}
